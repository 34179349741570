<template>
  <section >
    <div class="name">
        {{store.state.user.role}}: {{store.state.user.first_name}} {{store.state.user.last_name}}
    </div>
     <div class="task-area">
         <div class="upper">
<div class="task-title-area">
    <div class="task-title">
{{route.params.taskID}}
</div>
<div class="tasks-completed">{{3/3}} tasks complete</div>
</div>
<div :class="getStatusClass('completed')">Completed</div>
</div>
<div class="subtask-area">
    <div class="table-header">
        <div class="header-item">Task</div>
        <div class="header-item">Subtask</div>
        <div class="header-item">Status</div>
    </div>
    <div class="subtask">
        <div class="subtask-item"> Subtask Title</div>
        <div class="subtask-item"> Subtask Subtask</div>
        <div class="subtask-status subtask-item"> 
            <div class="status-name">Complete</div>
            <img :src="getIcon('completed')" class="status-icon"/>
        </div>

    </div>
    <div class="subtask">
        <div class="subtask-item"> Subtask Title</div>
        <div class="subtask-item"> Subtask Subtask</div>
        <div class="subtask-status subtask-item"> 
            <div class="status-name">Complete</div>
            <img :src="getIcon('completed')" class="status-icon"/>
        </div>

    </div>
</div>
<div class="assistance-area">
<div class="assistance-title">Assistance Request Messages</div>
<div class="assistance-item">
 <div class="assistance-item-left">
<div  class="assistance-date">date/date/date</div>
<div class="assistance-item-title">Title</div>
<div class="assistance-item-description">description</div>
</div>
<div class="assistance-item-right">
    <div class="assistance-button">Resolve</div>
</div>
</div>
</div>
     </div>
  </section>
</template>

<script setup>
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import attentionRequired from '@/assets/icons/attention-required-status.svg'
import completed from '@/assets/icons/completed-status.svg'

const router = useRouter()
const route = useRoute()
const store = useStore()

const getStatusClass = (status) => {
if(status === 'completed') return 'completed status'
}

const getIcon = (status) => {
    if(status === 'completed') return  completed
}
</script>

<style scoped>
.name{
    color: #FF8217;
font-size: 18px;
font-family: Helvetica Neue LT Std;
font-style: normal;
font-weight: 500;
line-height: normal;
text-transform: uppercase;
}
.header-item{
    text-align: left;
}
.subtask-status{
    display: flex;
    justify-content: center;
align-items: center;
}
.status-icon{
    margin-left: 5px;
}
.task-area{
border-radius: 20px;
background: #FFF;
display: flex;
width: 958px;
padding: 40px;
flex-direction: column;
justify-content: center;
gap: 40px;
}
.subtask-area{
border-radius: 10px;
border: 1px solid var(--light-grey, #E4E4E4);
}
.subtask{
    display: flex;
width: 878px;
padding: 10px 20px;
align-items: center;
gap: 30px;
justify-content: space-around;
border-top: 1px solid var(--light-grey, #E4E4E4);
}
.completed{
    color: var(--complete, #ABE2C8);
text-align: center;
font-size: 18px;
font-family: Helvetica Neue LT Std;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.status{
    margin-left: auto;
}
.upper{
    display: flex;
}
.task-title{
    color: #5F6562;
font-size: 24px;
font-family: Helvetica Neue LT Std;
font-style: normal;
font-weight: 700;
line-height: 120%;
}
.table-header{
display: flex;
width: 878px;
padding: 10px 20px;
align-items: center;
gap: 30px;
justify-content: space-around;
/* border-top: 1px solid var(--light-grey, #E4E4E4); */
background: var(--system-light-grey, #F4F6F5);
border-top-left-radius: 10px;
border-top-right-radius: 10px;

}
.subtask-item{
    text-align: left;
}
.assistance-area{
    border-radius: 10px;
border: 1px solid var(--light-grey, #E4E4E4);
display: flex;
padding: 40px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 30px;
align-self: stretch;
}
.assistance-title{
    color: var(--dark-grey, #5F6562);
font-family: Helvetica Neue LT Std;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 120%;
}
.assistance-item{
    display: flex;
padding-bottom: 0px;
justify-content: space-between;
align-items: center;
border-bottom: 1px solid var(--light-grey, #E4E4E4);
width: 100%;

}
.assistance-date{
    color: var(--medium-grey, #908F8F);
font-family: Helvetica Neue LT Std;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 120%;
}
.assistance-item-title{
    color: var(--alert, #B32D22);
font-family: Helvetica Neue LT Std;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 120%;
}
.assistance-item-description{
    color: #5F6562;
font-family: Helvetica Neue LT Std;
font-size: 16px;
font-style: italic;
font-weight: 400;
line-height: 142%;
}
</style>
